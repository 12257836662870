@import "Form";
@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "Menu";
@import "FullPageLoader";
@import "Target";

body {
  height: 100%;
}

h1 {
  color: $brand-primary;
  font-size: 28px;
}

.container {
  header {
    min-height: 70px;
    text-align: center;
  }
}

.logo {
  text-align: center;
}
